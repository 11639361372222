<script setup lang="ts">
import { apiAuth } from '@/services';

const { signinStructure } = useStructureAuth()
const structure = reactive(signinStructure)

const submit = async () => {
  structure.data.email = structure.data.email.trim()
  apiAuth.signin(structure.data)
    .then(() => {
      location.reload()
    })
    .catch(err => {
      console.error(err)
    })
}

const { t } = useI18n()

useHead({
  title: 'Signin',
  meta: [{ name: 'description', content: 'Signin page' }],
})

definePageMeta({
  layout: 'auth',
  middleware: 'unauth',
})
</script>

<template>
  <section class="signin">
    <main class="signin-container">
      <common-structure :module="structure" @submit="submit"></common-structure>
    </main>
    <auth-info-signin></auth-info-signin>
  </section>
</template>

<style scoped lang="scss">
.signin {
  @apply w-full;
}
</style>
