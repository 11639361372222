<script setup lang="ts">
  const { t } = useI18n();
  const localePath = useLocalePath();
</script>

<template>
  <footer>
    <span>{{ t('components.auth.info.signin.canNotLogin') }}{{ '\x20' }}</span>
    <nuxt-link :to="localePath({ name: Page.RECOVERY })">{{ t('components.auth.info.signin.restorePassword') }}</nuxt-link>
  </footer>
</template>

<style scoped lang="scss">
footer {
  @apply mt-8 text-center text-sm
    mx-auto max-w-[160px]
    sm:mx-0 sm:max-w-none;
  span {
    @apply text-gray-2;
  }
  a {
    @apply text-accent underline;
  }
}
</style>
